body {
	margin: 0;
}
.App {
	text-align: center;
	color: #fff;
	font-family: monospace;
	height: 100vh;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
	font-size: 2rem;
}
.app2 {
	background-color: white;
	color: black;
	font-family: monospace;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
}
.home {
	background-color: #3fc18c;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}
.home a {
	background-color: white;
	border: 3px solid black;
	padding: 3rem;
	width: 50%;
}
.home a img {
	max-height: 200px;
	max-width: 100%;
}
input {
	font-family: monospace;
	border: 0;
	padding: 1rem;
	font-size: 2rem;
	text-align: center;
	text-transform: uppercase;
	max-width: 80%;
	margin: 0 auto;
}
.valid {
	background-color: teal;
}

.notvalid {
	background-color: tomato;
}

p {
	width: 80%;
	margin: 0 auto;
}

.hidden-message {
	color: transparent;
}
.back-btn {
	position: absolute;
	left: 20px;
	top: 15px;
	color: #fff;
	text-decoration: none;
	font-family: initial;
}
.back-btn:hover {
	font-weight: 600;
}
.wollivan {
	position: absolute;
	bottom: 10px;
	right: 10px;
	font-size: 1rem;
	color: white;
	text-decoration: none;
}

aside {
	position: absolute;
	left: 0;
	font-size: 16px;
	padding-left: 8px;
}

li {
	list-style-type: none;
	text-align: left;
}

h5,
h6 {
	max-width: 800px;
}

a {
	color: rgb(155, 246, 124)
}